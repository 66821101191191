.slider-img{
    width: 95%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.group-slider{
    /* width: 50vw; */
    height: calc(50vh + 100px);
    display: flex;
    /*min-height: 600px;*/
    justify-content: center;
    align-items: center;
    background: rgb(34, 49, 63,0.1);
    backdrop-filter: blur(gray);
    margin-top: 20px;
    border: 1px solid gray;

}
.group-slider img {
    height: 100%;
    width: 100%;
    max-height: 600px;
    object-fit: scale-down;
    object-position: center;
    transition: all 0.5s ease-in-out;
}
.group-slider img.active{
    opacity: 1;
}
.nav-images{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    overflow-x: scroll;
    margin-top: 15px;
}
.nav-images::-webkit-scrollbar {
    display: none;
}
.nav-images div{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1E1E1E;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.nav-images div.active{
    background: #edcd1f;
}

.slider-btn{
    transition: 0.5s;
    cursor: pointer;
    width: clamp(20px, 5vw, 40px);
    height: clamp(20px, 5vw, 40px);
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider-btn svg {
    width: clamp(10px, 2vw, 10px);
    height: clamp(10px, 2vw, 20px);
    transition: 0.1s;
}
.slider-btn:hover svg {
    fill: #ffffff;
}
.slider-btn:hover {
    background: #5b5e63;
}
.slider-btn.left{
    left: 25% !important;
    rotate: 180deg;
}
.slider-btn.right{
    right: 25% !important;
}
.group-slider-btn{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 95%;
    max-width: 250px;
    gap: 10%;
}
.selection-slider{
    margin-top: 15px;
    padding: 10px;
    font-size: calc(10px + 1vw);
    text-align: center;
}
.license-img{
    max-width: 99%;
}