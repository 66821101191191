.gmap_iframe{
    width: 60vw;
    height: 300px;
    border: none;
    margin: 20px 0 0;
    padding: 0;
    overflow: hidden;
}
.map-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
}

@media screen and (max-width: 820px) {
    .gmap_iframe{
        width: 80vw;
        height: 300px;
    }
}
@media screen and (max-width: 600px) {
    .gmap_iframe{
        width: 90vw;
        height: 300px;
    }
}

@media screen and (max-width: 400px) {
    .gmap_iframe{
        width: 95vw;
        height: 300px;
    }
    
}