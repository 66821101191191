.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.image-nav{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    border-radius: 5px;
}
.image-nav button{
    background: rgba(0,0,0,0.5);
    color: white;
    border: none;
    font: 1rem sans-serif;
    padding: 10px;
    cursor: pointer;
    transition: 0.5s;
}
.image-nav button:hover{
    background: rgba(0,0,0,0.8);
}