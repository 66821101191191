.navbar {
    background: #22313F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 91px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}
.menu-active {
    color: #EDCD1F;
}
.navbar a {
    text-decoration: none;
    height: 100%;
}

.navbar a:hover .navbar__logo {
    background: #eda11f;
    transform: scale(1.1);
    transition: all 0.3s ease;
}

.navbar__menu {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    height: 100%;
    padding-right: 10px;
    font-size: 17px;
    color: white;
    gap: 50px;
    transition: all 0.3s ease;
}


.navbar__menu ul {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    height: 100%;
    gap: 20px;
    transition: all 0.3s ease-in-out;
}

.menu-active {
    color: #EDCD1F;
}

.navbar__menu ul li {
    cursor: pointer;
    text-transform: uppercase;
    font-size:12px;
    transition: all 0.3s ease-out;
}

.navbar__menu ul li:hover {
    color: #544a2a;
}

.navbar__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    background: #EDCD1F;
    height: 100%;
    letter-spacing: 0;
    initial-letter: 0;
}

.Est-logo {
    font-size: calc(10px + 2vmin);
    font-weight: 700;
    color: #000;
    text-decoration: none;
    text-transform: uppercase
}

.Est-sub-logo {
    font-size: calc(10px + 2vmin);
    font-weight: 400;
    color: #000;
    text-decoration: none;
    text-transform: uppercase
}

.logo-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.text-logo {
    color: #000;
    margin-left: 2rem;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.navbar__toggle {
    color: white;
    font-size: clamp(2rem, 5vw, 2.5rem);
    cursor: pointer;
    display: none;
}

.navbar__toggle:hover {
    color: #EDCD1F;
}

.user-section__logged-out {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.register {
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 7px;
    text-decoration: none;
    cursor: pointer;
}

.register:hover {
    color: #EDCD1F;
    border: 1px solid #EDCD1F;
}

.btn-login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 5px;
    padding: 10px 7px;
    text-decoration: none;
    cursor: pointer;
    color: #EDCD1F;
    font-weight: normal;
    text-transform: uppercase
}

.btn-login svg {
    fill: #EDCD1F;
}

.btn-login:hover {
    color: white;
}

.btn-login:hover svg {
    fill: white;
}
@media screen and (max-width: 980px) {
    .navbar__menu {
        gap: 10px;
    }
    .text-logo{
        margin-left: 10px;
    }
    .Est-sub-logo{
        font-size: 15px;
    }
    .Est-logo{
        font-size: 15px;
    }
}
@media screen and (max-width: 830px) {
    .navbar__logo {
        padding: 0 10px;
    }

    .navbar {
        height: 70px;
        /*justify-content: center;*/
        align-items: center;
    }

    .navbar__toggle {
        display: block;
        padding-right: 10px;
    }

    .navbar__menu {
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        transition: all 1s ease;
    }

    .navbar__menu {
        background: #22313F;
        height: auto;
        display: flex;
        z-index: 998;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .navbar__menu ul {
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 10px;
        text-align: center;
    }

    .navbar__menu ul li {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .navbar__menu.active {
        top: 70px;
    }
}