.video-bg {
    position: absolute;
    width: 100%;
    height: 500px;
    overflow: hidden;
    object-fit: cover;
    background-color: #22313F;
    background-size: cover;
    background-position: center;
    z-index: -1;
    left: 0;
}

.video-bg video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    object-fit: cover;
    z-index: -1;
    background: #c4c4c4;
}
@media screen and (max-width: 600px) {
    .video-bg video{
        display: none;
    }
}
.div-header {
    display: flex;
    align-content: center;
    height: 400px;
    justify-content: center;
}

.div-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    /*background: rgba(255,255,255,0.1);*/
    /*-webkit-backdrop-filter: blur(10px);*/
    /*backdrop-filter: blur(10px);*/
    padding: 15px;
    margin-top: 10px;
    border-radius: 15px;
}

.div-header-content hr {
    width: 100%;
    height: 3px;
    background: #EDCD1F;
    border: 1px solid #EDCD1F;
    box-shadow: black 0px 0px 10px;
}

.div-header-content b {
    font-size: clamp(18px, 4vw, 24px);
    color: white;
    text-shadow: black 0px 0px 10px;
    text-align: center;
    margin: 0;
}

.div-header-content span {
    font-size: clamp(14px, 4vw, 22px);
    color: white;
    text-shadow: black 0px 0px 10px;
    text-align: center;
    margin: 0;
}

.header-name {
    font-size: clamp(40px, 10vw, 90px);
    font-weight: bold;
    color: #EDCD1F;
    text-transform: uppercase;
    text-shadow: black 0px 0px 10px;
    text-align: center;
    margin: 0;
}

.div-service {
    margin-top: 300px;
    padding-top: 20px;
    background: #FFFFFF;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.admin-connect {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 75px;
    height: 75px;
    border-radius: 15px;
    background: #EDCD1F;
    cursor: pointer;
}

.admin-connect:hover {
    background: #EDCD1F;
    box-shadow: black 0px 0px 10px;
}

.title-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.title-div h2 {
    color: #1E1E1E;
    font-size: calc(1.5em + 1vw);
    font-weight: bolder;
    text-transform: uppercase;
    margin: 0 !important;

}

.title-div hr {
    width: 200px;
    height: 5px;
    background: #EDCD1F;
    border: 1px solid #EDCD1F;
    padding: 0;
}

.div-service-content {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: clamp(10px, 5%, 10px);
    padding-bottom: 100px;
    width: auto;
    justify-items: center;
    align-items: center;
}

.service {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    height: 500px;
    width: 300px;
    background: #D9D9D9;
    cursor: pointer;
    text-overflow: ellipsis;
    border: 1px solid #D9D9D9;
}

.service:hover {
    box-shadow: black 0px 0px 10px;
}

.service .service-img {
    width: 100%;
    height: 200px;
    max-height: 200px;
    background: #B5AF91;
}

.service-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.service .title {
    font-size: 18px;
    font-weight: bold;
    color: #1E1E1E;
    text-transform: uppercase;
    padding: 0 10px 0 20px;
    margin: 10px 0 0;
}
.service .subtitle {
    font-size: 16px;
    font-weight: bold;
    color: #1E1E1E;
    padding: 0 10px 0 20px;
    margin: 5px 0 0;
}

.service .paragraph {
    font-size: 16px;
    color: #1E1E1E;
    padding: 0 10px 0 20px;
    margin: 5px 0 0;
    max-height: 200px;
    height: 200px;
    overflow-y: auto;
}

.service .paragraph::-webkit-scrollbar {
    width: 5px;
}

.service .paragraph::-webkit-scrollbar-track {
    background: #ddd;
}

.service .paragraph::-webkit-scrollbar-thumb {
    background: #666;
}
.paragraph p {
    margin: 0 0 5px;
}

.div-services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: clamp(10px, 5%, 50px);
    margin-top: 40px;
}

.div-about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #F8F8F8;
    width: 100%;
    justify-items: start;
}

.about-content .title-div {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 100%;
}

.about-content {
    display: flex;
    justify-content: start;
    align-items: end;
    width: 100%;
    flex-direction: column;
}

.content-in-about {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    width: 500px;
    padding-top: 60px;
}

.content-in-about p {
    text-align: left;
    width: 95%;
    font-size: 20px;
    font-weight: lighter;
    color: #1E1E1E;
    margin: 30px 0 0;
}

.about-content hr {
    margin: 0;
}

.about-content .title-div {
    margin-top: 0 !important;
}

.about-group-img {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 100%;
}

.about-img {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.about-img:hover .about-bg {
    filter: brightness(50%);

}

.about-img:hover .about-logo {
    filter: brightness(100%);
    scale: 1.1;
    box-shadow: white 0 0 50px;

}

.about-img .about-bg {
    width: 400px;
    object-fit: cover;
    transition: 0.5s;
}

.about-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px !important;
    position: absolute;
    border-radius: 50%;
    box-shadow: black 0 0 10px;
    transition: 0.5s;
}

.div-status {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #edcd1f;
}

.card-status {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-status h1 {
    font-size: calc(1.5em + 1vw);
    font-weight: bolder;
    color: #1E1E1E;
    margin: 0;
}

.card-status span {
    font-weight: bold;
    color: #1E1E1E;
    margin: 0;
    text-align: center;
    font-size: calc(0.7em);
}

.card-status:nth-child(2) {
    border-left: white 5px solid;
    border-right: white 5px solid;
    padding-left: clamp(10px, 18%, 120px);
    padding-right: clamp(10px, 18%, 120px);
    margin-left: clamp(10px, 18%, 120px);
    margin-right: clamp(10px, 18%, 120px);
}

.div-license {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #FFFFFF;
}

.div-project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #F8F8F8;
}

.div-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #22313F;
}

.div-contact .title-div h2 {
    color: #F8F8F8 !important;
}

.contact-address {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
}

.div-grid {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: start;
    flex-direction: row;
    gap: clamp(10px, 10vw, 150px);
}

.div-grid .grid-element {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: start;
    flex-direction: column;
    color: #F8F8F8;

}

.div-grid .grid-element h2 {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}

.div-grid .grid-element p {
    padding: 0;
    font-size: 16px;
}
.div-grid .grid-element a{
    color: #F8F8F8;
    text-decoration: none;
}
.div-grid .grid-element a:hover{
    text-decoration: underline;
}


@media screen and (max-width: 1050px) {
    .div-about {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        padding-bottom: 50px;
        width: 100%;
    }

    .about-content {
        justify-content: center;
        align-items: center;
    }

    .div-about .about-content {
        align-items: center !important;
    }

    .div-about .about-group-img {
        align-items: center !important;
    }
}

@media screen and (max-width: 1000px) {
    .div-services {
        grid-template-columns: repeat(2, 1fr);

    }

    .card-status:nth-child(2) {
        border-left: white 3px solid;
        border-right: white 3px solid;
        padding-left: clamp(5px, 18%, 50px);
        padding-right: clamp(5px, 18%, 50px);
        margin-left: clamp(5px, 18%, 50px);
        margin-right: clamp(5px, 18%, 50px);
    }
}

@media screen and (max-width: 690px) {
    .div-services {
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 200px;
    }

    .card-status:nth-child(2) {
        border-left: white 2px solid;
        border-right: white 2px solid;
        padding-left: clamp(5px, 18%, 10px);
        padding-right: clamp(5px, 18%, 10px);
        margin-left: clamp(5px, 18%, 10px);
        margin-right: clamp(5px, 18%, 10px);
    }
}

@media screen and (max-width: 540px ) {
    .content-in-about .title-div {
        align-items: center;
    }

    .content-in-about p {
        font-size: 14px;
        padding: 0;
        padding-left: 10px;
    }

    .content-in-about {
        width: 100%;
        padding: 0;
    }

    .about-bg {
        width: clamp(200px, 80%, 300px) !important;
    }

    .about-img {
        width: auto !important;
    }

    .about-logo {
        width: clamp(100px, 80%, 150px) !important;
    }


    .about-group-img {
        padding: 0;
    }

    .about-group-img {
        padding: 0;
    }
}

@media screen and (max-width: 400px ) {
    .content-in-about p {
        font-size: 14px;
    }
}
@media screen and (max-width: 360px ) {
    .div-grid{
        flex-direction: column;
        width: 95%;
    }
    
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track {
    background: #ddd;
}

body::-webkit-scrollbar-thumb {
    background: #666;
}