.div-fb-post {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.fb-page {
    max-width: 99% !important;
}

._1z7u {
    right: 315px !important;
}

._2ph_ {
    right: 315px !important;
}

@media screen and (max-width: 511px) {}

.flex-post {
    display: flex;

    justify-content: center;
    align-items: start;
}

.group-post {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    width: 100%;
    justify-content: center;
    padding: 0;
}

.group-posts .fb-post:last-child {
    display: none !important;
}
.fb_iframe_widget{
    display: inline !important;
}

.fb-post {
    background: rgb(34, 49, 63, 0.1);
    height: 450px;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    border: none;
    display: none;
}

.fb-post span {
    height: 400px;

}

.fb-post iframe {
    width: 100% !important;
    border: none;
}

.fb-post::-webkit-scrollbar {
    width: 5px;
}

.fb-post::-webkit-scrollbar-track {
    background: #ddd;
}

.fb-post::-webkit-scrollbar-thumb {
    background: #666;
}

@media screen and (max-width: 1540px) {
    .group-post {
        grid-template-columns: repeat(2, 1fr);
    }

    .fb-post {
        height: 400px;
    }
}

@media screen and (max-width: 820px) {
    .group-post {
        grid-template-columns: repeat(1, 1fr);
    }

    .fb-post {
        height: 300px;

        /*overflow-y: auto;*/
    }

    .fb-post span {
        height: 300px;
        width: 100% !important;
    }
}