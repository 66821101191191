#root{
    font-size: calc(10px + 2vmin);
    padding: 0;
    margin: 0;
    margin-top: 70px;
}
@media screen and (max-width: 830px) {
    #root{
        margin-top: 50px;
    }
    
}